import React from 'react';

import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import {
  ArrowIconGoRight,
  HorizontalYellowLineShape,
  IconLectureTime,
} from '../../Atoms/Icons/Icons';
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import useMedias from '../../hooks/useMedias';
import SocialShare from '../../Molecules/SocialShare/SocialShare';

import './ArticleHighlight.scss';
import { trackCP, trackPublicationsDocument } from '../../Utils/Tracking';
import { trackActualite } from '../../Utils/Tracking';

let classNames = require('classnames');

const trackEvent = (category, title) => {

  switch (category) {
    case "actualites":
      trackActualite('clic_actualite', 'media', category, title, 'media')
      break;
    case "communiques":
      trackCP('clic_cp', 'media', category, title)
      break;
    case "pdf":
      trackPublicationsDocument('clic_pdf_telecharge', 'media', 'accueil', true, 'publications', title)
      break;
  }

}

const ArticleHighlight = ({ title, reading_time, linkTo, category, image, page_category }) => {
  const { processUrl } = useMedias();
  const intl = useIntl();
  let title_link = linkTo;
  let isLinkPdfPage = false;
  if (title_link.indexOf('.pdf') > 0) {
    title_link = process.env.GATSBY_METADATA_SITE_URL + intl.locale + '/pdf/?file=https:' + linkTo + '&title=' + title;
    isLinkPdfPage = true;
  }
  return (
    <div className="article_highlight">
      <div className="single_article">
        <div className="info_share">
          {category !== 8 && (
            <p className="lecture_time">
              <IconLectureTime
                className="time_icon"
                aria-label={reading_time}
              />
              <span>{reading_time}</span>
              <HorizontalYellowLineShape className="bottom_line" />
            </p>
          )}
          {category !== 8 && (
            <SocialShare
              title={title}
              linkTo={linkTo}
              vertical={true}
              hidemobile={true}
              pageCategory={page_category}
            />
          )}
        </div>
        <div className={classNames('visuel', { default_visuel: !image })}>
          {image ? (
            <img
              src={processUrl(image?.image_style_uri?.gatsby_medium)}
              alt={title}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          ) : (
            <StaticImage
              style={{ height: '100%', width: '100%' }}
              src="../../assets/images/home-page-banner.jpg"
              alt="placeholder"
              placeholder="blurred"
            />
          )}
        </div>
        <div className="info">
          <p>
            {isLinkPdfPage ? (
              <a href={title_link} target={isLinkPdfPage ? '_blank' : '_self'} rel="noreferrer" onClick={() => trackEvent('pdf', title)}>
                {title} <ArrowIconGoRight fill="#ffffff" />
              </a>
            ) : (
              <Link to={title_link} onClick={() => trackEvent(page_category, title)}>
                {title} <ArrowIconGoRight fill="#ffffff" />
              </Link>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

ArticleHighlight.propTypes = {
  reading_time: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
};

export default ArticleHighlight;
