import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../hooks/useMedias';
import { Button } from '../../Molecules/Button/Button';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../../Molecules/TitleSection/TitleSection';
import SliderComponent from '../SliderComponent/SliderComponent';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomeColasInnov.scss';
import { trackCTAEventsObj } from '../../Utils/Tracking';

let classNames = require('classnames');

const HomeColasInnov = ({
  innovationNode,
  imagesArray,
  page_type,
  page_name
}) => {
  const { processUrl, getImage } = useMedias();

  const slides = [];

  innovationNode.relationships.field_slides.forEach((item) => {
    let imageDesktop = getImage(
      imagesArray,
      item?.relationships?.field_image?.drupal_internal__mid
    );

    item.field_publish &&
      slides.push({
        image: imageDesktop,
        alt: item?.relationships?.field_image?.field_media_image?.alt || '',
        info: {
          text: item.field_title?.processed,
          link: item.field_link?.url,
          link_title: item.field_link?.title,
        },
      });
  });

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1.5,
    slidesToScroll: 1,
    speed: 300,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          arrows: false,
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <section
      className={classNames(
        'section_colas_innov',
        'section_content',
        innovationNode.field_backgroud_color != 'ice' &&
          innovationNode.field_backgroud_color != 'navy'
          ? 'index_top'
          : null
      )}
    >
      <ShapeBackground
        top={innovationNode.field_border_top && 'left'}
        color={
          innovationNode.field_backgroud_color === 'ice'
            ? 'catskill_white'
            : innovationNode.field_backgroud_color === 'navy'
              ? 'dark_bleu'
              : 'white'
        }
        bottomInfinite={innovationNode.field_infinite_border}
        bottom={innovationNode.field_border_bottom}
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={innovationNode.field_title?.processed}
            description={innovationNode.body?.processed?.replace(
              /<p[^>]*>/g,
              ''
            )}
            type="arrow"
          />
        </div>
        <div className="wrapper_page">
          <div
            className={classNames(
              'cross_wrapper_extreme_right',
              'extreme_both_mobile'
            )}
          >
            <SliderComponent settings={settings} page_name={page_name} page_type={page_type} >
              {slides.map((slide, i) => (
                <div key={i}>
                  {slide?.image && (
                    <img
                      src={processUrl(slide.image?.image_style_uri?.gatsby_innov_carousel)}
                      alt={slide.alt}
                      width="945"
                      height="510"
                      loading="lazy"
                      style={{
                        height: '123vw',
                        width: '100%',
                        maxHeight: '510px',
                        objectFit: 'cover',
                      }}
                    />
                  )}
                  <div className="info_bleu_box">
                    <p>{slide.info.text}</p>
                    {slide.info.link && (
                      <Button
                        primary={true}
                        label={slide?.info?.link_title}
                        link={slide?.info?.link}
                        aria-label={
                          slide?.info?.link_title + ' - ' + slide.info.text
                        }
                        onClickEvent={() => trackCTAEventsObj(
                          {
                            'event': 'clic_cta',
                            'page_name': page_name,
                            'page_type': page_type,
                            'zone': innovationNode.field_title?.processed,
                            'activity': '/',
                            'position': i,
                            'button_content': slide?.info?.link_title,
                            'button_type': 'cta',
                            'content_name': slide.info.text
                          }
                        )}
                      />
                    )}
                  </div>
                </div>
              ))}
            </SliderComponent>
          </div>
        </div>
      </ShapeBackground>
    </section>
  );
};

HomeColasInnov.propTypes = {
  innovationNode: PropTypes.object.isRequired,
  imagesArray: PropTypes.array.isRequired,
};

export default HomeColasInnov;
